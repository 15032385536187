import React from 'react';
import PropTypes from 'prop-types';

import Layout from '../../components/layout';
import Menu from '../../components/menuComponent';
import ContactCaptcha from '../../components/contactContainer';

const ContactContainer = ({ location }) => {
  const { pathname } = location;
  const filter = pathname.split('/').filter((pathString) => pathString.length > 0).pop();

  return (
    <Layout
      title="Request a Free Brochure | Wow Interior Design"
      description="Fill in our request a free brochure form letting us know the type of storage solution you are looking for & we will send you brochures to meet your requirements."
    >
      <Menu />
      <ContactCaptcha formType={filter} />
    </Layout>
  );
};

export default ContactContainer;

ContactContainer.propTypes = {
  location: PropTypes.object.isRequired,
};
